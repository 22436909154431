import React from 'react';
import { IBookTable } from '../dataProvider/IBookTable';
//const DOMPurify = require ('dompurify');
import i18n from '../i18n';

import DOMPurify from 'dompurify';

//import { Text } from "@fluentui/react";

interface IContentState {
  bookContent?: {};
}

interface ITableOfContentProps {
  bookTable: IBookTable;
  chapterIdx: number;
  setChapter: any;
  image: string;
  progress: string;
  contentHeight: string;
}

interface ITableOfContentState {
  contentHeight: string;
}

/*
To avoid the potential security vulnerabilities (such as XSS attacks) that are present when using dangerouslySetInnerHTML, you can do the following:

First use DOMPurify to clean the HTML.

  https://www.npmjs.com/package/dompurify
  
  npm install dompurify

let clean = DOMPurify.sanitize(dirtyHtmlString, {USE_PROFILES: {html: true}});

Then it can be rendered using react-render-html as Salman Lone said:

import renderHTML from 'react-render-html';

<div>
   {renderHTML(clean)}
</div>}
*/

const urlReg = /(http[s]*:\/\/[a-zA-Z.:0-9?&#/]+)/g;
const newLine = /\n/g;

class TableOfContent extends React.Component<ITableOfContentProps, ITableOfContentState> {
  constructor(props: ITableOfContentProps) {
    super(props);
    this.state = {
      contentHeight: props.contentHeight
    };
  }

  public componentDidMount(): void {
    console.log('TableOfContent componentDidMount: ', this.props.contentHeight);

    this.setState({
      contentHeight: `${parseInt(this.props.contentHeight) - document!.getElementById('idHeader')!.clientHeight}px`
    });

    /*
    const hh =
      document!.documentElement!.clientHeight -
      document!.getElementById("idHeader")!.clientHeight -
      document!.documentElement!.clientHeight / 100;
    const contentHeight = `${hh - this.props.headerHeight}px`;
    this.setState({ contentHeight });
    */
    //this.props.setcontentHeight(contentHeight);
  }

  public shouldComponentUpdate(nextProp: ITableOfContentProps) {
    console.log('TableOfContent shouldComponentUpdate');
    //when navigating between dates, the day in App is notified/changed
    // and when rendering whole again, it will pass a new date prop
    // but i am already in the new day... || this.props.sessionId !== nextProp.sessionId)
    if (this.props.contentHeight !== nextProp.contentHeight) {
      this.setState({
        contentHeight: `${parseInt(nextProp.contentHeight) - document!.getElementById('idHeader')!.clientHeight}px`
      });
    }
    return true;
  }

  render(): React.ReactNode {
    console.log('TableOfContent.render()');
    let description = this.props.bookTable.description?.replace(urlReg, "<a target='_blank' href=$1>$1</a>");
    description = description?.replace(newLine, '<br>');
    description = DOMPurify.sanitize(description, {
      USE_PROFILES: { html: true }
    });
    const useContentArray = Array.isArray(this.props.bookTable.content);
    const reverseOrder = this.props.bookTable.displayOrder === 'reverse';
    let nonSessionNum = 0;

    const sessions = useContentArray ? this.props.bookTable.content : this.props.bookTable.sessions;

    //Table of Content --- 目录, 内容, 笔记
    return (
      <>
        <div id='idHeader' className='ColumnHead'>
          {i18n.t('TableOfContent')}
        </div>
        <div className='ContentV' style={{ height: this.state.contentHeight }}>
          <h3 style={{ textAlign: 'center' }}>{this.props.bookTable.title}</h3>
          <img
            style={{
              width: '120px',
              height: '120px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
            alt={this.props.bookTable.title}
            src={this.props.image}
          />
          <div className='ChapterButtonList'>
            <div
              style={{ height: 'auto', width: '100%', border: 'none' }}
              dangerouslySetInnerHTML={{ __html: description || '' }}
            />
            {sessions?.map((session, index, arr) => {
              const idx = reverseOrder ? arr.length - 1 - index : index;
              const currentChapter = reverseOrder ? arr.length - 1 - this.props.chapterIdx : this.props.chapterIdx;
              if (useContentArray && session.type !== 'session') {
                nonSessionNum++;
              }
              /*
              //content directory is protected with security check!!
              if (useContentArray && session.type === "image") {
                return <img src={HostOfficialServer +
                  "/lesson/" +
                  encodeURIComponent(this.props.bookTable.lessonId as string) +
                  "/content/" + session.value
                }/>;
              }
              */
              return (
                <button
                  className='ChapterButton'
                  key={index}
                  onClick={() => {
                    if (useContentArray && session.type !== 'session') {
                      return;
                    }
                    this.props.setChapter(reverseOrder ? arr.length - 1 - index : index);
                  }}
                  style={{
                    backgroundColor:
                      session.type === 'text' ? 'white' : index === currentChapter ? '#4D4D4D' : 'LightGrey',
                    color: index === currentChapter ? 'white' : 'black'
                  }}>
                  ({index + 1}) &nbsp;
                  {arr[idx].title || arr[idx].value?.replace(/<[^<>]*>/g, '')}
                  <span>
                    {(index === this.props.chapterIdx && this.props.progress) ||
                      ((!useContentArray || session.type === 'session') &&
                        this.props.bookTable.progress![index - nonSessionNum]) ||
                      ''}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}
//<div className="ChapterSequence">{index + 1}</div>

export default TableOfContent;

//:(this.props.bookTable.progress[index] || '')
