import axios from 'axios';
import { wipeSignInState, getAuthStr } from '../SharedCommon/utils';

function createAxiosInstance(locale?: string) {
  const axiosInstance = axios.create();
  // set up request header configurations
  axiosInstance.interceptors.request.use(
    (config) => {
      const authToken = getAuthStr();
      if (authToken) {
        config.headers = {
          lang: locale,
          Authorization: authToken,
          'cache-control': 'no-cache'
        };
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // handle error msg 401 on response
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log('customAxios Error: ' + error.response?.status);
      if (error.response?.status === 401) {
        wipeSignInState();
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
}

export default createAxiosInstance;
