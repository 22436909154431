/*
dialogue box:
*/
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { _dataProvider } from '../dataProvider/DataProvider';

import { groupIcon, _smallScreen } from '../SharedCommon/utils';
import DefaultProfilePicture from '../img/icon-DefaultProfilePicture.png';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

import { IChatMessage } from '../dataProvider/IChatData';

const options: IChoiceGroupOption[] = [
  { key: 'A', text: 'Option A' },
  { key: 'B', text: 'Option B' },
  { key: 'C', text: 'Option C', disabled: true }
];
const modelProps = {
  isBlocking: false,
  styles: { main: { maxWidth: 450, borderRadius: '20px' } }
};

const dialogContentProps = {
  type: DialogType.largeHeader,
  showCloseButton: true,
  title: 'All emails together',
  subText: 'Your Inbox has changed. No longer does it include favorites, it is a singular destination for your emails.'
};

export const DialogLargeHeaderExample: React.FunctionComponent = () => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);

  return (
    <>
      <DefaultButton secondaryText='Opens the Sample Dialog' onClick={toggleHideDialog} text='Open Dialog' />
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modelProps}>
        <ChoiceGroup defaultSelectedKey='B' options={options} />
        <DialogFooter>
          <PrimaryButton onClick={toggleHideDialog} text='Save' />
          <DefaultButton onClick={toggleHideDialog} text='Cancel' />
        </DialogFooter>
      </Dialog>
    </>
  );
};

const promptExternalLinkProps = {
  type: DialogType.largeHeader,
  showCloseButton: true,
  title: i18n.t('authoring.EnterLink')
  //subText: "subText -- TBD",
};

export interface IPromptExternalLinkProps {
  label: string;
  url: string;
  onExit: any;
  idx: number;
}

export const PromptExternalLink: React.FunctionComponent<IPromptExternalLinkProps> = (props) => {
  const [{ url, label }, setLabelUrl] = useState({
    url: props.url,
    label: props.label
  });
  //let url = props.url;
  //let label = props.label;

  return (
    <Dialog
      hidden={false}
      onDismiss={() => props.onExit(undefined)}
      dialogContentProps={promptExternalLinkProps}
      modalProps={modelProps}>
      <h3 style={{ textAlign: 'left' }}>{i18n.t('authoring.ContentTitle')}</h3>
      <input
        key={i18n.t('authoring.ContentTitle')}
        value={label}
        onChange={(ev) => setLabelUrl({ url, label: ev.target.value })}
        style={{ width: '100%' }}></input>
      <h3 style={{ textAlign: 'left' }}>{i18n.t('authoring.UrlLink')}</h3>
      <input
        key={i18n.t('authoring.UrlLink')}
        value={url}
        onChange={(ev) => setLabelUrl({ url: ev.target.value, label })}
        style={{ width: '100%' }}></input>
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            props.onExit(url, label || i18n.t('authoring.ExternalLink'), props.idx);
          }}
          text={i18n.t('authoring.Save')}
        />
        <DefaultButton onClick={() => props.onExit(undefined)} text={i18n.t('authoring.Cancel')} />
      </DialogFooter>
    </Dialog>
  );
};

const promptDocumentLabel = {
  type: DialogType.largeHeader,
  showCloseButton: true,
  title: i18n.t('authoring.EnterTitle')
  //subText: "subText -- TBD",
};

export interface IPromptDocumentLabelProps {
  label: string;
  onExit: any;
  idx: number;
}

export const PromptDocumentLabel: React.FunctionComponent<IPromptDocumentLabelProps> = (props) => {
  const [{ label }, setLabel] = useState({
    label: props.label
  });
  //let url = props.url;
  //let label = props.label;

  return (
    <Dialog
      hidden={false}
      onDismiss={() => props.onExit(undefined)}
      dialogContentProps={promptDocumentLabel}
      modalProps={modelProps}>
      <h3 style={{ textAlign: 'left' }}>{i18n.t('authoring.DocTitle')}</h3>
      <input
        key='Label'
        value={label}
        onChange={(ev) => setLabel({ label: ev.target.value })}
        style={{ width: '100%' }}></input>
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            props.onExit(label, props.idx);
          }}
          text={i18n.t('authoring.Save')}
        />
        <DefaultButton onClick={() => props.onExit(props.label, props.idx)} text={i18n.t('authoring.Cancel')} />
      </DialogFooter>
    </Dialog>
  );
};

const promptForScriptProps = {
  type: DialogType.largeHeader,
  showCloseButton: true,
  title: i18n.t('authoring.EnterScript')
  //subText: "subText -- TBD",
};

export interface IPromptForScriptProps {
  jsonTxt: string;
  onExit: any;
  idx: number;
  iii: number;
}

export const PromptForScript: React.FunctionComponent<IPromptForScriptProps> = (props) => {
  const [jsonTxt, setJsonTxt] = useState(props.jsonTxt);
  return (
    <Dialog
      hidden={false}
      onDismiss={() => props.onExit(undefined)}
      dialogContentProps={promptForScriptProps}
      modalProps={modelProps}>
      <textarea
        key='description'
        style={{ width: '100%', resize: 'vertical' }}
        rows={6}
        value={jsonTxt}
        placeholder={i18n.t('authoring.HereEnterScript')}
        onChange={(ev) => setJsonTxt(ev.target.value)}
      />
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            props.onExit(jsonTxt, props.idx, props.iii);
          }}
          text={i18n.t('authoring.Save')}
        />
        <DefaultButton onClick={() => props.onExit(undefined)} text={i18n.t('authoring.Cancel')} />
      </DialogFooter>
    </Dialog>
  );
};

export interface IPromptPickGroupProps {
  pickGroup: any;
}

/*
    this._dataProvider.getGroups().then((groups)=>{
      if (groups) {
        alert("您暂没有加入小组. 请在移动终端, 用这个链接 https://idigest.app/install 下载idigest软件APP, 注册后, 创建小组或加入小组, 就可以有线上小组电话了");
        return;
      }

      /*
      return <DialogLargeHeaderExample/>;
          {!!this.state.externLinkProps && (
            <PromptExternalLink
              url={this.state.externLinkProps.url}
              label={this.state.externLinkProps.label}
              onExit={this.state.externLinkProps.onExit}
              idx={this.state.externLinkProps.idx}
            />
          )}

      //this._dataProvider.getGroups();
const prompt4Group = {
  type: DialogType.largeHeader,
  title: "请选择小组",
  //title: "Please pick a small group by click",
  //subText: "subText -- TBD",
};
*/

export const PromptPickGroup: React.FunctionComponent<IPromptPickGroupProps> = (props) => {
  const [groupList, setGroups] = useState([]);
  const [txt, setTxt] = useState('Loading...');
  const { t } = useTranslation();

  if (!groupList || groupList.length === 0) {
    //_dataProvider.getManagedOrgsAndGroups().then((data: any) => {
    _dataProvider.getUserGroups().then((groups: any) => {
      if (!groups) {
        setTxt('Server error, check network connection, and try again');
        return;
      }
      if (groups.length === 0) {
        setTxt(t('NoGroup'));
      } else {
        setGroups(groups);
      }
    });
  }

  const IDigestLogo = _dataProvider.getLogo();
  return (
    <Dialog
      hidden={false}
      onDismiss={() => props.pickGroup(undefined)}
      dialogContentProps={{
        showCloseButton: true
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { borderRadius: '20px' } },
        containerClassName: 'PickGroupDialogue'
      }}>
      <img alt='iDigestApp' className='centeriDigestLogo' src={IDigestLogo} />
      <br></br>
      <br></br>
      <h3 style={{ textAlign: 'center' }}>{!!groupList && groupList.length > 0 ? t('ChooseGroup') : txt}</h3>
      <div className='PickGroup'>
        {!!groupList &&
          groupList.map((item: any) => (
            <ul key={item.groupId} className='buttonColor' style={{ padding: 0 }}>
              <DefaultButton
                className='orgGroupButton'
                styles={{
                  flexContainer: {
                    justifyContent: 'left',
                    marginLeft: 25
                  }
                }}
                //styles={ root: [flexContainer:[justifyContent:"left"]] }
                onClick={() => props.pickGroup(item.groupId)}>
                <img
                  id='OrgImage'
                  src={groupIcon(item)}
                  className='groupSymbol'
                  alt='Group Symbol'
                  onError={(e) => {
                    console.log('image error event: ', e);
                    (e.target as HTMLImageElement).src = DefaultProfilePicture;
                  }}></img>{' '}
                {item.Name || item.name}
              </DefaultButton>
            </ul>
          ))}
      </div>
    </Dialog>
  );
};

export interface IPromptPickFilesInGroupProps {
  groupId: number;
  processFile: (groupId: number, fileId: number) => Promise<void>;
  onDismiss: () => void;
}

export const IPromptPickFilesInGroup: React.FunctionComponent<IPromptPickFilesInGroupProps> = (props) => {
  const [fileList, setFileList] = useState<IChatMessage[] | undefined | null>(undefined);
  const { t } = useTranslation();
  const [dayRange, setDayRange] = useState(36);

  React.useEffect(() => {
    const getFiles = async () => {
      const files = await _dataProvider.getFileListInGroup(props.groupId, dayRange);
      setFileList(files);
    };
    getFiles();
  }, [dayRange]);

  const IDigestLogo = _dataProvider.getLogo();

  const fileName = (name: string) => {
    if (name.startsWith('文件 ') || name.startsWith('音频 ') || name.startsWith('图片 ')) {
      return name.slice(3);
    }
    return name;
  };

  return (
    <Dialog
      hidden={false}
      onDismiss={() => {
        props.onDismiss();
      }}
      dialogContentProps={{
        showCloseButton: true
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { borderRadius: '20px' } },
        containerClassName: 'PickGroupDialogue'
      }}>
      <img alt='iDigestApp' className='centeriDigestLogo' src={IDigestLogo} />
      <br></br>
      <br></br>
      <h3 style={{ textAlign: 'center' }}>
        {fileList === undefined
          ? 'Loading...'
          : fileList === null
          ? 'Server error, please try again'
          : fileList.length === 0
          ? t('NoFile')
          : t('ChooseFile')}
      </h3>
      <div className='PickGroup' style={{ marginLeft: 0 }}>
        {!!fileList &&
          fileList.map((item: any, index) => (
            <ul key={index} className='buttonColor' style={{ padding: 0 }}>
              <DefaultButton
                className='orgGroupButton'
                style={{ width: '100%', padding: 4, borderRadius: 0 }}
                styles={{
                  flexContainer: {
                    marginLeft: 8
                  }
                }}
                onClick={() => props.processFile(props.groupId, item.fileId)}>
                {!_smallScreen && (
                  <div style={{ width: '100%' }}>
                    <p style={{ float: 'left' }}>{new Date(item.time * 1000).toLocaleDateString()}</p>
                    <p style={{ float: 'right' }}>{fileName(item.displayName)}</p>
                  </div>
                )}
                {_smallScreen && fileName(item.displayName)}
              </DefaultButton>
            </ul>
          ))}
        {dayRange === 36 && !!fileList && fileList.length > 0 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <DefaultButton
              className='orgGroupButton'
              style={{ backgroundColor: 'lightgray', marginTop: 25 }}
              styles={{
                flexContainer: {
                  marginLeft: 25
                }
              }}
              onClick={() => setDayRange(178)}>
              {i18n.t('Chatroom.HalfYear')}
            </DefaultButton>
          </div>
        )}
      </div>
    </Dialog>
  );
};
