import React, { useState } from 'react';
import { _dataProvider } from '../dataProvider/DataProvider';
import { t } from 'i18next';
import './ClassRegistration.css';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Dialog } from '@fluentui/react/lib/Dialog';

interface IAddContactProps {
  onClose: () => void;
}

const AddContactWindow: React.FC<IAddContactProps> = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [introduction, setIntroduction] = useState('');

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleIntroductionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIntroduction(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      await _dataProvider.inviteUser(email, introduction);
      onClose(); // Close the modal upon successful submission
    } catch (error) {
      console.error('Failed to add contact:', error);
      alert(t('FailedToAddContact'));
    }
  };

  return (
    <Dialog
      hidden={false}
      onDismiss={onClose}
      dialogContentProps={{
        showCloseButton: true
      }}
      modalProps={{
        isBlocking: false,
        styles: {
          main: {
            borderRadius: '20px'
          }
        }
      }}>
      <div style={{ textAlign: 'center' }}>
        <b style={{ fontSize: '24px', color: 'grey', fontWeight: 'bold' }}>{t('AddNewContact')}</b>{' '}
        {/* Adjusted font size */}
        <div className='input-container'>
          <p>{t('PleaseEnterEmail')}</p>
          <input id='contactEmail' type='email' value={email} onChange={handleEmailChange} />
        </div>
        <div className='input-container'>
          <p>{t('SelfIntroduction')}</p>
          <textarea id='contactIntroduction' value={introduction} onChange={handleIntroductionChange} />
        </div>
        <DefaultButton
          onClick={handleSubmit}
          styles={{
            root: {
              margin: 'auto',
              marginTop: '20px', // Ensure there's a gap between textarea and button
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '25px'
            }
          }}>
          Ok
        </DefaultButton>
      </div>
    </Dialog>
  );
};

export default AddContactWindow;
