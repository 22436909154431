import React from 'react';
import { IBookTable } from '../dataProvider/IBookTable';
import { _dataProvider } from '../dataProvider/DataProvider';
import axios from 'axios';
import { isUserSignedIn } from '../SharedCommon/utils';
import i18n from '../i18n';
import { SingleRoleUserMgmt } from './SingleRoleUserMgmt';

import { Stack, ChoiceGroup, IChoiceGroupOption, FontIcon, IconButton, DefaultButton } from '@fluentui/react';

//import { useConst} from '@fluentui/react-hooks';
import {
  //ContextualMenuItemType,
  IContextualMenuProps
  //IContextualMenuItemProps,
} from '@fluentui/react/lib/ContextualMenu';
//import { Icon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';

import { registerIcons } from '@fluentui/react/lib/Styling';
import { ChevronDownEnd6Icon } from '@fluentui/react-icons-mdl2';
import { ChevronUpEnd6Icon } from '@fluentui/react-icons-mdl2';

registerIcons({
  icons: {
    ChevronDownEnd6Icon: <ChevronDownEnd6Icon />
  }
});
registerIcons({
  icons: {
    ChevronUpEnd6Icon: <ChevronUpEnd6Icon />
  }
});

interface IWritingTableOfContentProps {
  bookTable: IBookTable;
  chapterIdx: number;
  setChapter: any;
  image: string;
  lang: string;
  bookStateKey: string;
  updateBookState: any;
  duplicateChapter: any;
  contentHeight: string;
  preview: boolean;
}

interface IWritingTableOfContentState {
  bookTable: IBookTable;
  image: string;
  hashTime: number;
  contentHeight: string;
  bookStateKey: string;
}

/*
 */
const options: IChoiceGroupOption[] = [
  { key: 'A', text: i18n.t('authoring.UnpublishedRule'), styles: { root: { minHeight: '18px' } } },
  { key: 'B', text: i18n.t('authoring.PrivateRule'), styles: { root: { minHeight: '18px' } } },
  { key: 'C', text: i18n.t('authoring.LimitedRule'), styles: { root: { minHeight: '18px' } } },
  { key: 'D', text: i18n.t('authoring.PublishedRule'), styles: { root: { minHeight: '18px' } } }
];

const iconClass = mergeStyles({
  fontSize: 30,
  margin: '15px 35px 0px 35px'
});

class WritingTableOfContent extends React.Component<IWritingTableOfContentProps, IWritingTableOfContentState> {
  private saveCount: number;
  private currentChapterTitle?: string;
  private currentIdx: number;
  private lastSaveSuccess: boolean;
  private titleDirty: boolean;
  private coverImageObj: any;

  constructor(props: IWritingTableOfContentProps) {
    super(props);
    this.saveCount = 0;
    this.titleDirty = false;
    this.lastSaveSuccess = true;

    this.currentIdx = props.chapterIdx;
    this.currentChapterTitle = this.currentIdx === -1 ? '' : props.bookTable.sessions[this.currentIdx]?.title;

    this.state = {
      bookTable: props.bookTable,
      image: props.image,
      hashTime: Date.now(),
      contentHeight: props.contentHeight,
      bookStateKey: props.bookStateKey
    };

    this.saveEvery5Seconds(0, this.props.bookStateKey);
  }

  public componentDidMount(): void {
    console.log('WritingTableOfContent componentDidMount: ', this.props.contentHeight);

    this.setState({
      contentHeight: `${parseInt(this.props.contentHeight) - document!.getElementById('idHeader')!.clientHeight}px`
    });
  }

  //need to know the chapter title was updated in the content editor
  //that will come through the props.
  public shouldComponentUpdate(nextProps: IWritingTableOfContentProps) {
    console.log(
      'WritingTableOfContent shouldComponentUpdate:',
      this.currentIdx,
      nextProps.chapterIdx,
      nextProps.contentHeight
    );
    if (nextProps.bookTable !== this.state.bookTable) {
      const bookTable = nextProps.bookTable;
      this.setState({ bookTable });
    }

    let sessions = nextProps.bookTable?.sessions;
    if (Array.isArray(nextProps.bookTable?.content)) {
      sessions = nextProps.bookTable?.content;
    }

    //sessions could become empty/null after deleting the last chapter
    if (this.currentIdx >= 0 && (!sessions || !sessions[this.currentIdx])) {
      this.currentIdx = -1;
      this.currentChapterTitle = '';
      console.log('shouldComponentUpdate: sessions null?', sessions, this.currentIdx);
    }

    if (this.currentIdx === nextProps.chapterIdx) {
      if (this.currentIdx >= 0) {
        if (this.currentChapterTitle !== sessions[this.currentIdx].title) {
          this.saveCount++;
          this.currentChapterTitle = sessions[this.currentIdx].title;
        }
      }
    } else {
      this.currentIdx = nextProps.chapterIdx;
      if (this.currentIdx >= sessions.length) {
        this.currentIdx = 0;
      }
      this.currentChapterTitle = undefined;
      if (this.currentIdx >= 0) {
        this.currentChapterTitle = sessions[this.currentIdx].title;
      }
    }
    if (this.props.contentHeight !== nextProps.contentHeight) {
      this.setState({
        contentHeight: `${parseInt(nextProps.contentHeight) - document!.getElementById('idHeader')!.clientHeight}px`
      });
    }
    return true;
  }

  private moveUp = (idx: number, noRecursion = false) => {
    console.log('moveUp: ', idx, noRecursion, this.currentIdx);
    const reverseOrder = this.props.bookTable.displayOrder === 'reverse';
    if (reverseOrder && !noRecursion) {
      this.moveDown(idx, true);
      return;
    }
    if (idx === 0) {
      return;
    } // the item is already at the top

    this.saveCount++;
    const bookTable = this.state.bookTable;

    const useContentArray = Array.isArray(bookTable.content);

    let sessions = bookTable.content;
    if (!useContentArray) {
      sessions = bookTable.sessions;
    }

    //first delete the item
    const upItem = sessions!.splice(idx, 1);

    //then insert back at a position higher
    sessions!.splice(idx - 1, 0, ...upItem);

    if (this.currentIdx === idx) {
      this.props.setChapter(idx - 1, false);
    } else if (this.currentIdx === idx - 1) {
      this.props.setChapter(idx, false);
    }

    this.setState({ bookTable });
  };

  private moveTop = (idx: number, noRecursion = false) => {
    console.log('moveTop: ', idx, noRecursion, this.currentIdx);
    const reverseOrder = this.props.bookTable.displayOrder === 'reverse';
    if (reverseOrder && !noRecursion) {
      this.moveBottom(idx, true);
      return;
    }
    if (idx === 0) {
      return;
    } // the item is already at the top

    this.saveCount++;
    const bookTable = this.state.bookTable;

    const useContentArray = Array.isArray(bookTable.content);

    let sessions = bookTable.content;
    if (!useContentArray) {
      sessions = bookTable.sessions;
    }

    //first delete the item
    const upItem = sessions!.splice(idx, 1);

    //then insert back at a position higher
    sessions!.splice(0, 0, ...upItem);

    if (this.currentIdx === idx) {
      this.props.setChapter(0, false);
    } else if (this.currentIdx < idx) {
      this.props.setChapter(this.currentIdx + 1, false);
    }

    this.setState({ bookTable });
  };

  private _delete = (idx: number) => {
    this.saveCount++;
    const bookTable = this.state.bookTable;

    const useContentArray = Array.isArray(bookTable.content);
    let sessions = bookTable.content;
    if (!useContentArray) {
      sessions = bookTable.sessions;
    }

    sessions!.splice(idx, 1);

    if (!sessions || sessions.length === 0) {
      //this would be back to when creating a new book, insert an empty standard first chapter...
      //const ttt = bookTable.content?.title;
      bookTable.sessions = _dataProvider.getEmptyBook(bookTable.lessonId).sessions;
      sessions = bookTable.sessions;
    }

    if (this.currentIdx >= idx) {
      this.currentIdx -= 1;
      if (this.currentIdx < 0) {
        this.currentIdx = 0;
      }
      this.props.setChapter(this.currentIdx, this.currentIdx === idx - 1);
    }

    this.setState({ bookTable });
  };

  private moveDown = (idx: number, noRecursion = false) => {
    console.log('moveDown: ', idx, noRecursion);
    const reverseOrder = this.props.bookTable.displayOrder === 'reverse';
    if (reverseOrder && !noRecursion) {
      this.moveUp(idx, true);
      return;
    }

    this.saveCount++;
    const bookTable = this.state.bookTable;

    const useContentArray = Array.isArray(bookTable.content);
    let sessions = bookTable.content;
    if (!useContentArray) {
      sessions = bookTable.sessions;
    }

    if (idx === sessions!.length - 1) {
      return;
    } // the item is already at the bottom

    //first delete the item
    const downItem = sessions!.splice(idx, 1);

    //then insert back at a position higher
    sessions!.splice(idx + 1, 0, ...downItem);

    if (this.currentIdx === idx) {
      this.props.setChapter(idx + 1, false);
    } else if (this.currentIdx === idx + 1) {
      this.props.setChapter(idx, false);
    }
    this.setState({ bookTable });
  };

  private moveBottom = (idx: number, noRecursion = false) => {
    console.log('moveBottom: ', idx, noRecursion);
    const reverseOrder = this.props.bookTable.displayOrder === 'reverse';
    if (reverseOrder && !noRecursion) {
      this.moveTop(idx, true);
      return;
    }

    this.saveCount++;
    const bookTable = this.state.bookTable;

    const useContentArray = Array.isArray(bookTable.content);
    let sessions = bookTable.content;
    if (!useContentArray) {
      sessions = bookTable.sessions;
    }

    if (idx === sessions!.length - 1) {
      return;
    } // the item is already at the bottom

    //first delete the item
    const downItem = sessions!.splice(idx, 1);

    //then insert back at a bottom
    sessions!.splice(sessions!.length, 0, ...downItem);
    //sessions!.push(downItem);

    if (this.currentIdx === idx) {
      this.props.setChapter(sessions!.length - 1, false);
    } else if (this.currentIdx > idx) {
      this.props.setChapter(this.currentIdx - 1, false);
    }
    this.setState({ bookTable });
  };

  private menuProps = (idx: number): IContextualMenuProps => {
    return {
      shouldFocusOnMount: true,
      items: [
        {
          key: 'MoveTop',
          iconProps: { iconName: 'ChevronUpEnd6Icon' },
          text: i18n.t('authoring.Move2Top'), // Move to Top
          onClick: () => this.moveTop(idx)
        },
        {
          key: 'MoveUp',
          iconProps: { iconName: 'Up' },
          text: i18n.t('authoring.MoveUp'), //Move up
          onClick: () => this.moveUp(idx)
        },
        {
          key: 'Delete',
          iconProps: { iconName: 'Delete' },
          text: i18n.t('authoring.Delete'), //Delete
          onClick: () => this._delete(idx)
        },
        {
          key: 'Duplicate',
          iconProps: { iconName: 'Copy' },
          text: i18n.t('authoring.Duplicate'), //Duplicate
          onClick: () => this.addChapter(idx)
        },
        {
          key: 'MoveDown',
          iconProps: { iconName: 'Down' },
          text: i18n.t('authoring.MoveDown'), //Move Down
          onClick: () => this.moveDown(idx)
        },
        {
          key: 'MoveBottom',
          iconProps: { iconName: 'ChevronDownEnd6Icon' },
          text: i18n.t('authoring.Move2Bottom'), //Move to Bottom
          onClick: () => this.moveBottom(idx)
        }
      ]
    };
  };

  render(): React.ReactNode {
    console.log('WritingTableOfContent.render()');
    const useContentArray = Array.isArray(this.props.bookTable.content);
    const reverseOrder = this.props.bookTable.displayOrder === 'reverse';
    const sessions = useContentArray ? this.props.bookTable.content : this.props.bookTable.sessions;

    return (
      <>
        <div id='idHeader' className='ColumnHead'>
          {i18n.t('authoring.ClassOutline')}
        </div>
        <div className='ContentV' style={{ height: this.state.contentHeight }}>
          {!_dataProvider.getOrgId() && <SingleRoleUserMgmt contextId={this.props.bookTable.lessonId} role='Author' />}
          <div className='AuthorClassDivs'>
            <h3 className='AuthorClassHeadings'>{i18n.t('authoring.ClassState')}</h3>
            <div style={{ width: 'auto', border: '1px solid black' }}>
              <ChoiceGroup
                key='classState'
                style={{ marginLeft: '4px', marginBottom: '4px' }}
                selectedKey={this.state.bookStateKey}
                options={options}
                onChange={(ev, option) => {
                  if (!this.props.preview && option?.key === 'D') {
                    ev!.preventDefault();
                    alert(i18n.t('authoring.Want2Publish'));
                    _dataProvider.postFeedback(`I want to publish my book: ${this.state.bookTable.title}`);
                    return;
                  }
                  this.setState({ bookStateKey: option!.key });
                }}
                label=''
              />
            </div>
          </div>
          <div className='AuthorClassDivs'>
            <h3 className='AuthorClassHeadings'>{i18n.t('authoring.ClassName')}</h3>
            <input
              key='titleText'
              value={this.state.bookTable.title}
              onChange={this._updateTitle}
              style={{ width: '97%', padding: '4px' }}></input>
          </div>
          <div className='AuthorClassDivs'>
            <h3 className='AuthorClassHeadings'>{i18n.t('authoring.Cover')}</h3>
            <div
              onClick={this._updateCover}
              style={{
                position: 'relative',
                marginTop: '2px',
                width: '92px',
                height: '92px',
                marginLeft: '-46px',
                marginRight: '0px',
                border: '1px solid black',
                left: '50%'
              }}>
              <div
                style={{
                  margin: '0px',
                  zIndex: -1,
                  position: 'absolute',
                  top: '0px'
                }}>
                <FontIcon
                  aria-label='AddCover'
                  iconName='Add'
                  className={iconClass}
                  style={{ margin: '15px 31px 0px' }}
                />
                <p style={{ marginLeft: '25px' }}>{i18n.t('authoring.AddCover')}</p>
              </div>
              <img
                id='idCoverImage'
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '90px',
                  height: '90px',
                  minWidth: '90px',
                  minHeight: '90px',
                  maxWidth: '90px',
                  maxHeight: '90px',
                  visibility: 'visible'
                }}
                src={`${this.state.image}?last=${this.state.hashTime}`}
                ref={(element) => (this.coverImageObj = element)}
                alt={''}
                onError={(e) => {
                  console.log('cover image load failed: ', this.state.image);
                  (e.target as HTMLButtonElement).style.visibility = 'hidden';
                }}
              />
            </div>
          </div>
          <div className='AuthorClassDivs'>
            <h3 className='AuthorClassHeadings'>{i18n.t('authoring.ClassIntro')}</h3>
            <textarea
              key='description'
              style={{ width: '97%', resize: 'vertical', padding: '4px' }}
              rows={6}
              value={this.state.bookTable.description}
              placeholder={i18n.t('authoring.IntroSample')}
              onChange={this._updateDescription}
            />
          </div>
          <div className='AuthorClassDivs'>
            <h3 className='AuthorClassHeadings' style={{ textAlign: 'right', width: '88%' }}>
              {i18n.t('authoring.TableOfContent')}
            </h3>
            <Stack tokens={{ childrenGap: 5 }}>
              {sessions?.map((session, index, arr) => {
                const idx = reverseOrder ? arr.length - 1 - index : index;
                const currentChapter = reverseOrder ? arr.length - 1 - this.props.chapterIdx : this.props.chapterIdx;
                return (
                  <div key={100 + index}>
                    <button
                      className='UpdateChapterButton'
                      key={index}
                      onClick={() => {
                        if (useContentArray && session.type !== 'session') {
                          return;
                        }
                        this.currentChapterTitle = session.title;
                        this.currentIdx = idx;
                        this.props.setChapter(idx);
                      }}
                      style={{
                        backgroundColor:
                          session.type === 'text' ? 'white' : index === currentChapter ? '#4D4D4D' : 'LightGrey',
                        color: index === currentChapter ? 'white' : 'black'
                      }}>
                      {arr[idx].title || arr[idx].value?.replace(/<[^<>]*>/g, '')}
                    </button>
                    <DefaultButton
                      title='More'
                      ariaLabel='More'
                      key={1000 + index}
                      style={{
                        float: 'right',
                        border: '1px solid black',
                        width: '30px',
                        minWidth: '30px',
                        borderRadius: '50%',
                        marginTop: '5px'
                      }}
                      menuIconProps={{ iconName: 'More' }}
                      menuProps={this.menuProps(idx)}
                    />
                  </div>
                );
              })}
              <IconButton
                className='UpdateChapterButton'
                iconProps={{ iconName: 'Add' }}
                title='Add'
                ariaLabel='Add'
                style={{
                  backgroundColor: 'LightGrey',
                  color: 'black',
                  fontWeight: 'bold',
                  marginBottom: '20px'
                }}
                onClick={() => this.addChapter(-1)}>
                <span>{i18n.t('authoring.AddChapter')}</span>
              </IconButton>
            </Stack>
          </div>
        </div>
      </>
    );
  }

  private addChapter = (idx: number) => {
    //const chapterName = prompt('enter chapter name and title as one string');
    if (idx >= 0 && this.currentIdx !== idx) {
      alert('Duplicate only works on current chapter. Select a chapter, and then duplicate.');
      return; /// only dup if the current chapter is idx --
    }

    const bookTable = this.state.bookTable; // } as IBookTable;
    const useContentArray = Array.isArray(bookTable.content);

    let sessions = bookTable.content;
    if (!useContentArray) {
      if (!bookTable.sessions) {
        bookTable.sessions = [];
      }
      sessions = bookTable.sessions;
    }
    //console.log(bookTable, sessions);
    const chapterName =
      idx >= 0 ? 'copy of ' + sessions![idx].title : i18n.t('authoring.Chapter') + (sessions!.length + 1);

    if (chapterName) {
      //let chapterId = chapterName.substring(0, 16); //just initial 8 character;
      //chapterId = chapterId.replace(/[%: ]/g, 'A');
      let chapterId = 'C' + (sessions!.length + 1) + '_';
      const timeStamp = Math.floor(Date.now());
      chapterId += timeStamp;
      chapterId += '.json';

      const chapterIdx =
        sessions!.push({
          title: chapterName,
          lesson: chapterId,
          devotion: []
        }) - 1;
      if (useContentArray) {
        sessions![chapterIdx].type = 'session';
      }

      ///TODO: there is some problem here, as there is no content data yet for this new chapter??
      /// --- so how to cause a "new" chapter refresh on the phone emulator /content editor??
      // --- this in authoring tool used to wait for a "setChapter" click that causes a fetch of nothing, and then it
      // refreshes the phone/content editor accordingly ---  we could force that cycle automatically with new chapter??
      // it's better to unify this new chapter experience

      //Do the following of auto select the new chapter. For now, let user click on it as we do in the old authoring tool..
      //Now a problem: does the parent's bookTable has the new chapter in the array? if not, it will bomb --- so this needs
      //some testing ---
      //the old model, the state is in the parent only..., gets pushed down through props again.
      this.saveCount++;

      this.currentChapterTitle = chapterName;
      this.currentIdx = chapterIdx;

      console.log('addChapter: ', chapterIdx, idx);
      this.setState({ bookTable });

      if (idx >= 0) {
        this.props.duplicateChapter(chapterIdx, idx, chapterId, chapterName);
      } else {
        this.props.setChapter(chapterIdx);
      }
    }
  };

  private _updateCover = () => {
    const inputF = document.createElement('input');
    inputF.setAttribute('type', 'file');
    inputF.setAttribute('accept', '.jpg,.png');

    inputF.onchange = (e): void => {
      // eslint-disable-next-line
      const tg:any = e?.target;
      const file = tg?.files[0];
      const filename = file?.name;

      //alert('The file "' + filename + '" has been selected.');
      if (!file || !filename) {
        return;
      }
      let filenameAtServer = 'cover' + filename.slice(-4);
      if (filename.slice(-4).toLowerCase() === 'jpeg') {
        filenameAtServer = 'cover' + '.jpg';
      }
      if (filenameAtServer !== 'cover.jpg' && filenameAtServer !== 'cover.png') {
        alert(i18n.t('authoring.BookImageExtension'));
        return;
      }

      //var fileInput = document.getElementById('the-file');
      _dataProvider
        .uploadFile(this.state.bookTable.lessonId, file, this.props.lang, null, filenameAtServer)
        .then((success) => {
          if (success) {
            //alert('Book cover successfully uploaded, check out on your phone');
            alert(i18n.t('authoring.UploadCoverSuccess'));
            const hashTime = Date.now();
            this.coverImageObj.style.visibility = 'visible';
            axios
              .get(this.state.image, {
                headers: { 'Cache-Control': 'no-cache' }
              })
              .then(() => {
                this.setState({ hashTime });
              });
          } else {
            alert('Book cover upload failed, F5 refresh the page and try again');
          }
        });
    };
    inputF.click();
    return;
  };

  private _updateTitle = (ev: React.ChangeEvent<HTMLInputElement>) => {
    //ev.target.value
    const bookTable = this.state.bookTable;
    bookTable.title = ev.target.value;

    this.saveCount++;
    this.titleDirty = true;

    //console.log("_updateTitle: ", this.saveCount, this.titleDirty);
    this.setState({ bookTable });
  };

  private _updateDescription = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    //ev.target.value
    const bookTable = this.state.bookTable;
    bookTable.description = ev.target.value;
    this.saveCount++;
    this.setState({ bookTable });
  };

  async saveEvery5Seconds(saveCount: number, bookStateKey: string) {
    let newSaveCount = this.saveCount;
    if (this.state.bookStateKey !== bookStateKey) {
      console.log('saveEvery5Seconds:updateBookState', saveCount, bookStateKey, this.state.bookStateKey);
      if (!isUserSignedIn()) {
        //alert("登录失效，需要重新登录"); //user will retry
        return;
      }
      if (this.props.updateBookState(this.state.bookStateKey)) {
        bookStateKey = this.state.bookStateKey;
      }
    }
    if (this.saveCount > saveCount) {
      console.log('saveEvery5Seconds: ', this.saveCount, saveCount, this.titleDirty);
      let result = await _dataProvider.saveContent(this.state.bookTable, this.props.lang, 'free.json');
      if (result) {
        if (this.titleDirty) {
          console.log('update book title: ', result);
          result = await _dataProvider.updateBookTitle(this.state.bookTable.lessonId, this.state.bookTable.title);
          if (result) {
            this.titleDirty = false;
          }
          console.log('update book title: ', result, this.titleDirty);
        }
      }
      if (!result) {
        //We don't want to annoy ever 5 second if there is no connection?
        if (this.lastSaveSuccess) {
          alert('Data save failed, please check connection, will auto try saving every 5 seconds');
        }
        this.lastSaveSuccess = false;
        newSaveCount = saveCount;
      } else {
        this.lastSaveSuccess = true;
      }
    }
    // start the next one
    setTimeout(
      (s, k?) => {
        this.saveEvery5Seconds(s, k);
      },
      500,
      newSaveCount,
      bookStateKey
    );
  }
}

export default WritingTableOfContent;
